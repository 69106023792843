import { 
    UserInfo,
    SellerInfo,
    PropertyDetails,
    PopupPropertyDetails,
    PaymentFees,
    PropertyDateTime,
    PropertyLaunchDateTime,
    PhotographerDateTime,
    PreviewTab,
    PreviewSubPage,
    PresentType,
    UserTracking,
    SignInAgreement
} from "./actionCreator"

export const actions = dispatch => ({
    userInfoDispatch: (data) => dispatch(UserInfo(data)),
    sellerInfoDispatch: (data) => dispatch(SellerInfo(data)),
    propertyDetDispatch: (data) => dispatch(PropertyDetails(data)),
    popupPropertyDetDispatch: (flag, data) => dispatch(PopupPropertyDetails(flag, data)),
    PaymentFeesDispatch: (data) => dispatch(PaymentFees(data)),
    PropertyDateTimeDispatch: (data) => dispatch(PropertyDateTime(data)),
    PropertyLaunchDateTimeDispatch: (data) => dispatch(PropertyLaunchDateTime(data)),
    PhotographerDateTimeDispatch: (data) => dispatch(PhotographerDateTime(data)),
    PreviewTabDispatch: (data) => dispatch(PreviewTab(data)),
    PreviewSubPageDispatch: (data) => dispatch(PreviewSubPage(data)),
    PresentTypeDispatch: (data) => dispatch(PresentType(data)),
    UserTrackingDispatch: (data) => dispatch(UserTracking(data)),
    SignInAgreementDispatch: (data) => dispatch(SignInAgreement(data)),
});