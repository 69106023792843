import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './client';
import { ContainerContextProvider } from "../store/ContainerContext";
import { Offline, Online } from "react-detect-offline";

export const wrapRootElement = ({ element }) => (
  <>
  <Online>
    <ApolloProvider client={client}>
      <ContainerContextProvider>
        {element}
      </ContainerContextProvider>    
    </ApolloProvider>
  </Online>
  <Offline>
    <ApolloProvider client={client}>
      <ContainerContextProvider>
        {element}
      </ContainerContextProvider>    
    </ApolloProvider>
  </Offline>

  </>
);