import {LOGGED_USER_INFO, SELLER_INFO, PROPERTY_DETAILS, POPUP_PROPERTY_DETAILS, PAYMENT_FEES, PROPERTY_DATE_TIME, PROPERTY_LAUNCH_DATE_TIME, PHOTOGRAPHER_DATE_TIME, PREVIEW_TAB, PREVIEW_SUB_PAGE, PRESENT_TYPE, USER_TRACKING, SIGN_IN_AGGREMENT} from '../actions/actionsTypes'

export const CommonReducer = (state, action) => {
    switch (action.type) {

      case LOGGED_USER_INFO:
        return { 
            ...state,
            logged_user_data: action.logged_user_data
        };
      
      case SELLER_INFO:
        return { 
            ...state,
            seller_data: action.seller_data
        };

      case PROPERTY_DETAILS:
        return { 
            ...state,
            property_data: action.property_data
        };
      
      case POPUP_PROPERTY_DETAILS:
        return { 
            ...state,
            popup_property_comparable: {flag:action.compare_flag, item:action.compare_item}
        };

      case PAYMENT_FEES:
        return { 
            ...state,
            payment_fees: action.payment_fees
        };

      case PROPERTY_DATE_TIME:
        return { 
            ...state,
            property_date_time: action.property_date_time
        };

      case PROPERTY_LAUNCH_DATE_TIME:
        return { 
            ...state,
            property_launch_date_time: action.property_launch_date_time
        };

      case PHOTOGRAPHER_DATE_TIME:
        return { 
            ...state,
            photographer_date_time: action.photographer_date_time
        };

      case PREVIEW_TAB:
        return { 
            ...state,
            preview_tab: action.preview_tab
        };

      case PREVIEW_SUB_PAGE:
        return { 
            ...state,
            preview_sub_page: action.preview_sub_page
        };

      case PRESENT_TYPE:
        return { 
            ...state,
            present_type: action.present_type
        };
      
      case USER_TRACKING:
        return { 
            ...state,
            user_tracking: action.user_tracking
        };

      case SIGN_IN_AGGREMENT:
        return { 
            ...state,
            sign_in_aggrement: action.sign_in_aggrement
        };
          
      default:
        throw new Error();
    }
};