export const LOGGED_USER_INFO = 'LOGGED_USER_INFO';
export const SELLER_INFO = 'SELLER_INFO';
export const PROPERTY_DETAILS = 'PROPERTY_DETAILS';
export const POPUP_PROPERTY_DETAILS = 'POPUP_PROPERTY_DETAILS';
export const PAYMENT_FEES = 'PAYMENT_FEES';
export const PROPERTY_DATE_TIME = 'PROPERTY_DATE_TIME';
export const PROPERTY_LAUNCH_DATE_TIME = 'PROPERTY_LAUNCH_DATE_TIME';
export const PHOTOGRAPHER_DATE_TIME = 'PHOTOGRAPHER_DATE_TIME';
export const PREVIEW_TAB = 'PREVIEW_TAB';
export const PREVIEW_SUB_PAGE = 'PREVIEW_SUB_PAGE';
export const PRESENT_TYPE = 'PRESENT_TYPE';
export const USER_TRACKING = 'USER_TRACKING';
export const SIGN_IN_AGGREMENT = 'SIGN_IN_AGGREMENT';