import React from "react";
import { CommonReducer } from "./reducers/CommonReducer"
import { actions } from "./actions/dispatch"

const initialState = { count: 0, logged_user_data: '', seller_data: {}, property_data: {}, popup_property_comparable: {flag:false, item:''}, payment_fees: {}, property_date_time: {}, property_launch_date_time: {}, photographer_date_time: {}, preview_tab: {current_tab:'Intro', prev_tab:'first', next_tab:'', slider_item:0}, preview_sub_page: '', present_type: '', sign_in_aggrement: {}, user_tracking: {PageViews:{}}};

const ContainerContext = React.createContext(initialState);

export const ContainerContextProvider = props => {
  const [reducerState, dispatch] = React.useReducer(CommonReducer, initialState);
  const reducerActions = actions(dispatch);
  const context = {
    state: { ...reducerState },
    actions: { ...reducerActions }
  };

  return (
    <ContainerContext.Provider value={context}>
      {props.children}
    </ContainerContext.Provider>
  );
};

export const useContainerContext = () => React.useContext(ContainerContext);
