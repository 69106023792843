import {LOGGED_USER_INFO, SELLER_INFO, PROPERTY_DETAILS, POPUP_PROPERTY_DETAILS, PAYMENT_FEES, PROPERTY_DATE_TIME, PROPERTY_LAUNCH_DATE_TIME, PHOTOGRAPHER_DATE_TIME, PREVIEW_TAB, PREVIEW_SUB_PAGE, PRESENT_TYPE, USER_TRACKING, SIGN_IN_AGGREMENT} from './actionsTypes'

export const UserInfo = (user_data) => ({ 
    type: LOGGED_USER_INFO,
    logged_user_data: user_data
});

export const SellerInfo = (seller_data) => ({    
    type: SELLER_INFO,
    seller_data: seller_data
});

export const PropertyDetails = (property_data) => ({    
    type: PROPERTY_DETAILS,
    property_data: property_data
});

export const PopupPropertyDetails = (flag, data) => ({    
    type: POPUP_PROPERTY_DETAILS,
    compare_flag: flag,
    compare_item: data
});

export const PaymentFees = (payment_fees) => ({    
    type: PAYMENT_FEES,
    payment_fees: payment_fees
});

export const PropertyDateTime = (property_date_time) => ({    
    type: PROPERTY_DATE_TIME,
    property_date_time: property_date_time
});

export const PropertyLaunchDateTime = (property_launch_date_time) => ({    
    type: PROPERTY_LAUNCH_DATE_TIME,
    property_launch_date_time: property_launch_date_time
});

export const PhotographerDateTime = (photographer_date_time) => ({    
    type: PHOTOGRAPHER_DATE_TIME,
    photographer_date_time: photographer_date_time
});

export const PreviewTab = (preview_tab) => ({    
    type: PREVIEW_TAB,
    preview_tab: preview_tab
});

export const PreviewSubPage = (preview_sub_page) => ({    
    type: PREVIEW_SUB_PAGE,
    preview_sub_page: preview_sub_page
});

export const PresentType = (present_type) => ({    
    type: PRESENT_TYPE,
    present_type: present_type
});

export const UserTracking = (data) => ({    
    type: USER_TRACKING,
    user_tracking: data
});

export const SignInAgreement = (data) => ({    
    type: SIGN_IN_AGGREMENT,
    sign_in_aggrement: data
});


